<template>
  <div>
     <el-card >
       <el-table :data="portInfoList" style="font-size: smaller;width: 100%">
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" align="center" style="font-size: 13px" sortable fixed width="120">
              <template #default="scope">
                <el-input v-model="scope.row.ctnrId" size="small" v-if="scope.$index+apFlag===-1"></el-input>
                <span v-else>{{scope.row.ctnrId}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="bookId" :label="$t('msg.track.bookId')" align="center" style="font-size: 13px" sortable fixed width="120">
              <template #default="scope">
                <el-input v-model="scope.row.bookId" size="small" v-if="scope.$index+apFlag===-1"></el-input>
                <span v-else>{{scope.row.bookId}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mbl" :label="$t('msg.track.mbl')" align="center" style="font-size: 13px" sortable fixed width="150">
              <template #default="scope">
                <el-input v-model="scope.row.mbl" size="small" v-if="scope.$index+apFlag===-1"></el-input>
                <span v-else>{{scope.row.mbl}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="client" :label="$t('msg.track.client')" align="center" width="250" sortable></el-table-column>
            <el-table-column prop="pod" :label="$t('msg.track.pod')" align="center" width="120" sortable></el-table-column>
            <el-table-column prop="firmsCode" :label="$t('msg.track.firmsCode')" align="center" width="100" sortable>
              <template #default="scope">
                <el-input v-model="scope.row.firmsCode" size="small" v-if="scope.$index+apFlag===-1"></el-input>
                <span v-else>{{scope.row.firmsCode}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="dischargeTime" :label="$t('msg.track.discharge')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.dischargeTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.dischargeTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="appointTime" :label="$t('msg.track.appointTime')" align="center" width="150">
              <template #default="scope">
                <el-date-picker v-model="scope.row.appointTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.appointTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="lfd" :label="$t('msg.track.lfd')" align="center" width="120" sortable>
              <template #default="scope">
                <el-date-picker v-model="scope.row.lfd" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.lfd}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="outGateTime" :label="$t('msg.track.fullGateOut')" align="center" width="150">
              <template #default="scope">
                <el-date-picker v-model="scope.row.outGateTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.outGateTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="whDeliveryAppointTime" :label="$t('msg.track.whDelApp')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.whDeliveryAppointTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.whDeliveryAppointTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="actualArrivalTime" :label="$t('msg.track.dWHArrival')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.actualArrivalTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%" />
                <span v-else>{{scope.row.actualArrivalTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emptyNotifyTime" :label="$t('msg.track.dWHEmptyNotify')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.emptyNotifyTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%"/>
                <span v-else>{{scope.row.emptyNotifyTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emptyPickUpTime" :label="$t('msg.track.emptyPickUpTime')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.emptyPickUpTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%"/>
                <span v-else>{{scope.row.emptyPickUpTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emptyReturnAppointTime" :label="$t('msg.track.emptyReturnAppointTime')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.emptyReturnAppointTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%"/>
                <span v-else>{{scope.row.emptyReturnAppointTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="emptyReturnTime" :label="$t('msg.track.emptyReturnTime')" align="center" width="120">
              <template #default="scope">
                <el-date-picker v-model="scope.row.emptyReturnTime" value-format="YYYY-MM-DD" size="small" v-if="scope.$index+apFlag===-1" style="width: 100%"/>
                <span v-else>{{scope.row.emptyReturnTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ctnrSize" :label="$t('msg.track.ctnrSize')" align="center" width="120">
              <template #default="scope">
                <el-select v-model="scope.row.ctnrSize" size="small" v-if="scope.$index+apFlag===-1">
                  <el-option label="20GP" value="20GP"></el-option>
                  <el-option label="40GP" value="40GP"></el-option>
                  <el-option label="40HQ" value="40HQ"></el-option>
                  <el-option label="45HQ" value="45HQ"></el-option>
                </el-select>
                <span v-else>{{scope.row.ctnrSize}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="weight" :label="$t('msg.track.weight')" align="center" width="120">
              <template #default="scope">
                <el-input-number v-model="scope.row.weight" size="small" controls-position="right" :min="0" :step="1" v-if="scope.$index+apFlag===-1" style="width: 100%"></el-input-number>
                <span v-else>{{scope.row.weight}}</span>
              </template>
            </el-table-column>
         <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center" width="150px">
           <template #default="scope">
             <el-button link type="text" size="small" @click="confirmEdit(scope.row)" plain v-if="scope.$index+apFlag===-1">Confirm</el-button>
             <el-button link type="text" size="small" @click="cancelEdit()" plain v-if="scope.$index+apFlag===-1">Cancel</el-button>
             <el-button link type="text" size="small" @click="startEdit(scope.$index)" v-if="scope.$index+apFlag!==-1">Edit</el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-card>
     <el-card v-show="curName==='me'">
       <el-table :data="billTempList" >
         <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
         <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center" ></el-table-column>
         <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
         <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center"></el-table-column>
         <el-table-column prop="amount" :label="$t('msg.account.amount')" align="center"></el-table-column>
       </el-table>
       <el-input-number v-model="myId" size="small" controls-position="right" :min="0" :step="1"></el-input-number>
       <el-button type="primary" size="small" @click="testPortInfo">Test PortInfo</el-button>
     </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import axios from 'axios'
export default defineComponent({
  name: 'portInfo',
  setup() {
    const apFlag = ref(0)
    const portInfoList = ref([])
    const billTempList = ref([])
    const myId = ref(0)
    const curName = ref(localStorage.getItem('curUser'))
    const startEdit = (id) => {
      apFlag.value = -(id + 1)
    }
    const cancelEdit = () => {
      initPage()
      apFlag.value = 0
    }
    const confirmEdit = (row) => {
      apFlag.value = 0
      const param = 'curName=' + curName.value + '&newPortInfo=' + JSON.stringify(row)
      const reqURL = process.env.VUE_APP_BASE + '/dray/updateByPortInfo'
      axios.post(reqURL, param).then((response) => {
        portInfoList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const initPage = () => {
      const param = 'curName=' + curName.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/getMyAllPortInfo'
      axios.post(reqURL, param).then((response) => {
        portInfoList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const testPortInfo = () => {
      const param = 'portInfoForDray=' + JSON.stringify(portInfoList.value[myId.value]) + '&pol=SHA' + '&etd=20221225' + '&carrier=COSCO' + '&p2pSrv=SP-SP-SP-FBA-CD'
      const reqURL = process.env.VUE_APP_BASE + '/expense/p2pCombo'
      axios.post(reqURL, param).then((response) => {
        billTempList.value = response.data.ofCharge
        console.log(response.data.chbCharge)
        console.log(response.data.drayCharge)
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      initPage()
    })
    return { apFlag, portInfoList, billTempList, myId, curName, startEdit, cancelEdit, testPortInfo, confirmEdit }
  }
})
</script>
